import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zmbush.com/zmbush.com/main/src/components/layouts/default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I created my Glookup Frontend as a tool to make checking one's grades easier. As such, I do not
expect, or even plan to ask for any monetary gain from this project. I created it merely as a
service to the students of the University of California at Berkeley who need to interact with
Glookup.`}</p>
    <h2 {...{
      "id": "personal-data"
    }}>{`Personal Data`}</h2>
    <p>{`The only data stored on the device is the username associated with the account, the server you wish
to connect to, and an ssh private key used to authenticate with the server.`}</p>
    <p>{`Your password is no longer stored on the device as of version 2.1 Beta.`}</p>
    <p>{`None of the information stored on the device will ever be collected for any reason. It is your data,
and I have no need for it.`}</p>
    <h2 {...{
      "id": "contact"
    }}>{`Contact`}</h2>
    <p>{`For any privacy concerns, feel free to email me: `}<a parentName="p" {...{
        "href": "mailto:app+glookup@zmbush.com"
      }}>{`here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      